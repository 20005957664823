<template>
  <section>
    <van-nav-bar title="采购商品" left-arrow @click-left="backSpace" />
    <div class="module">
      <div @click="tonew" class="item faj">采购新商品</div>
      <div @click="tohave" class="item faj">已有商品</div>
    </div>
  </section>
</template>
<script>
import Vue from "vue";
import { NavBar } from "vant";
Vue.use(NavBar);
import { Toast } from "vant";
Vue.use(Toast);
export default {
  data() {
    return {};
  },
  computed: {},
  methods: {
    tonew() {
      this.$router.push("new");
    },
    tohave() {
      this.$router.push("have");
    },
  },
  created() {},
  mounted() {},
};
</script>
<style scoped>
.module {
  width: 70%;
  margin: 30px auto 0 auto;
}
.item {
  height: 80px;
  color: #fff;
  background: #094771;
  border-radius: 8px;
  font-weight: bold;
  font-size: 20px;
  margin-bottom: 30px;
}
</style>
